import React from "react";
import {Link} from "gatsby";
import {Row, Col, Container} from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/Layout/SEO";

import {FaUserShield, FaRegHandshake, FaUserSlash, FaHandHoldingUsd, FaGlobe, FaPowerOff} from "react-icons/fa";
import BottomCTA from "../sections/camelo/BottomCTA";

const LegalPage = () => {

  return (
    <>
      <PageWrapper>
        <SEO title="Terms & Policies"></SEO>
        <div className="inner-banner bg-default-1 pt-25 pt-lg-29">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="12" xl="12">
                <div className="px-md-15 text-center mb-5 mb-lg-13">
                  <h2 className="title gr-text-2 mb-9 mb-lg-12">
                    <span className="text-primary">Camelo</span> Terms & Policies
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-default-9 py-7 py-lg-14">
          <Container>
            <Row
              data-aos="fade-left"
              data-aos-duration="420"
              data-aos-easing="ease-in-out-quart"
              className="justify-content-center"
            >
              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link to="/policies/terms-of-service" className="card-img">
                    <div className="w-100 text-center d-flex justify-content-center align-items-center" style={{height: "150px", backgroundColor: "#111"}}>
                      <FaRegHandshake className="text-white gr-text-2"></FaRegHandshake>
                    </div>
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/policies/terms-of-service">
                      <p className="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">
                        Terms of Service
                      </p>
                    </Link>
                    <Link to="/policies/terms-of-service">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        By using the Services you agree to be bound by these Terms
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link to="/policies/privacy" className="card-img">
                    <div className="w-100 text-center d-flex justify-content-center align-items-center" style={{height: "150px", backgroundColor: "#111"}}>
                      <FaUserShield className="text-white gr-text-2"></FaUserShield>
                    </div>
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/policies/privacy">
                      <p className="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">
                        Privacy Policy
                      </p>
                    </Link>
                    <Link to="/policies/privacy">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        What information we collect at Camelo, how we use it, and what choices you have
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link to="/policies/cancellation" className="card-img">
                    <div className="w-100 text-center d-flex justify-content-center align-items-center" style={{height: "150px", backgroundColor: "#111"}}>
                      <FaPowerOff className="text-white gr-text-2"></FaPowerOff>
                    </div>
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/policies/cancellation">
                      <p className="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">
                        Cancellation Policy
                      </p>
                    </Link>
                    <Link to="/policies/cancellation">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Everything you need to know about canceling your Camelo product account.
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link to="/policies/refund" className="card-img">
                    <div className="w-100 text-center d-flex justify-content-center align-items-center" style={{height: "150px", backgroundColor: "#111"}}>
                      <FaHandHoldingUsd className="text-white gr-text-2"></FaHandHoldingUsd>
                    </div>
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/policies/refund">
                      <p className="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">
                        Refund Policy
                      </p>
                    </Link>
                    <Link to="/policies/refund">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        A fair refund policy
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link to="/policies/abuse" className="card-img">
                    <div className="w-100 text-center d-flex justify-content-center align-items-center" style={{height: "150px", backgroundColor: "#111"}}>
                      <FaUserSlash className="text-white gr-text-2"></FaUserSlash>
                    </div>
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/policies/abuse">
                      <p className="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">
                        Use Restrictions policy
                      </p>
                    </Link>
                    <Link to="/policies/abuse">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        It is not okay to use Camelo products for these restricted purposes.
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link to="/policies/until-the-end-of-internet" className="card-img">
                    <div className="w-100 text-center d-flex justify-content-center align-items-center" style={{height: "150px", backgroundColor: "#111"}}>
                      <FaGlobe className="text-white gr-text-2"></FaGlobe>
                    </div>
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/policies/until-the-end-of-internet">
                      <p className="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">
                        Until the End of the Internet
                      </p>
                    </Link>
                    <Link to="/policies/until-the-end-of-internet">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Unlike most other companies that make web software, we’re dedicated to supporting our products forever.
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="border-top">
          <BottomCTA />
        </div>

      </PageWrapper >
    </>
  );
};
export default LegalPage;
